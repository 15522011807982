import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PrinterService implements OnDestroy {
  private hubConnection: HubConnection;
  private updateInvoiceSubject = new Subject<boolean>();
  private sendNumberSubject = new Subject<any>();
  private updatePrintersSubject = new Subject<boolean>();
  private paymentReceivedSubject = new Subject<boolean>();
  private reconnectInterval: number = 5000; // 5 segundos
  private isManuallyDisconnected: boolean = false; // Nueva bandera

  updateInvoice$ = this.updateInvoiceSubject.asObservable();
  sendNumber$ = this.sendNumberSubject.asObservable();
  updatePrinters$ = this.updatePrintersSubject.asObservable();
  paymentReceived$ = this.paymentReceivedSubject.asObservable();

  private connectionPromise: Promise<void> | null = null;

  constructor() {}

  private ensureConnected(): Promise<void> {
    if (!this.hubConnection || this.hubConnection.state !== HubConnectionState.Connected) {
      if (!this.connectionPromise) {
        this.connectionPromise = new Promise((resolve, reject) => {
          this.connect(resolve, reject);
        });
      }
      return this.connectionPromise;
    }
    return Promise.resolve();
  }

  private connect(resolve: () => void, reject: (err: any) => void): void {
    SignalRAspNetCoreHelper.initSignalR(() => {
      abp.signalr.startConnection('/signalr-printerHub', (connection) => {
        this.hubConnection = connection;
        this.registerOnServerEvents();
        this.hubConnection.onclose(() => {
          if (!this.isManuallyDisconnected) {
            this.reconnect();
          }
        });
        resolve();
      }).catch(err => {
        console.error('Error al iniciar la conexión SignalR: ', err);
        setTimeout(() => this.connect(resolve, reject), this.reconnectInterval);
        reject(err);
      });
    });
  }

  private reconnect(): void {
    console.log('Conexión perdida. Intentando reconectar...');
    this.connectionPromise = new Promise((resolve, reject) => {
      setTimeout(() => this.connect(resolve, reject), this.reconnectInterval);
    });
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('UpdateInvoices', (message: boolean) => {
      this.updateInvoiceSubject.next(message);
    });

    this.hubConnection.on('SendNumber', (number: any) => {
      this.sendNumberSubject.next(number);
    });

    this.hubConnection.on('UpdatePrinters', (message: boolean) => {
      this.updatePrintersSubject.next(message);
    });

    this.hubConnection.on('PaymentReceived', (message: boolean) => {
      this.paymentReceivedSubject.next(message);
    });
  }

  disconnect(): void {
    this.isManuallyDisconnected = true; // Marca la desconexión manual
    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
      this.hubConnection.stop()
        .then(() => {
          console.log('Desconectado del hub de SignalR');
        })
        .catch(err => console.error('Error al desconectar la conexión de SignalR: ', err));

        this.connectionPromise = null;
    }
  }

  ngOnDestroy(): void {
    this.disconnect(); // Llama a desconectar al destruir el servicio
  }

  onUpdateInvoices(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.updateInvoice$;
  }

  onSendNumber(): Observable<any> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.sendNumber$;
  }

  onUpdatePrinters(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.updatePrinters$;
  }

  onPaymentReceived(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.paymentReceived$;
  }
}
