import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AbpHttpInterceptor } from "abp-ng2-module";

import * as ApiServiceProxies from "./service-proxies";

@NgModule({
  providers: [
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.ConfigurationServiceProxy,
    ApiServiceProxies.FeatureServiceProxy,
    ApiServiceProxies.PropertyGroupServiceProxy,
    ApiServiceProxies.PropertyServiceProxy,
    ApiServiceProxies.ColorServiceProxy,
    ApiServiceProxies.SizeServiceProxy,
    ApiServiceProxies.ProductServiceProxy,
    ApiServiceProxies.StoreServiceProxy,
    ApiServiceProxies.SalePointServiceProxy,
    ApiServiceProxies.CurrencyServiceProxy,
    ApiServiceProxies.ProviderPaymentServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.TemplateServiceProxy,
    ApiServiceProxies.ClientServiceProxy,
    ApiServiceProxies.CashierServiceProxy,
    ApiServiceProxies.BankAccountServiceProxy,
    ApiServiceProxies.WarehouseServiceProxy,
    ApiServiceProxies.StockServiceProxy,
    ApiServiceProxies.VoucherServiceProxy,
    ApiServiceProxies.DiscountServiceProxy,
    ApiServiceProxies.StorageServiceProxy,
    ApiServiceProxies.PrinterServiceProxy,
    ApiServiceProxies.TaxServiceProxy,
    ApiServiceProxies.SoftwareServiceProxy,
    ApiServiceProxies.ReservationServiceProxy,
    ApiServiceProxies.PromotionServiceProxy,
    ApiServiceProxies.BankServiceProxy,
    ApiServiceProxies.CompanyServiceProxy,
    ApiServiceProxies.BinesServiceProxy,
    ApiServiceProxies.ReportServiceProxy,
    ApiServiceProxies.SellerServiceProxy,
    ApiServiceProxies.ReportsServiceProxy,
    ApiServiceProxies.ProductGroupServiceProxy,
    ApiServiceProxies.LoyaltyCategoryServiceProxy,
    ApiServiceProxies.TenantGroupServiceProxy,
    ApiServiceProxies.PriceListServiceProxy,
    ApiServiceProxies.ProductPriceServiceProxy,
    ApiServiceProxies.QuotesServiceProxy,
    ApiServiceProxies.GatewayPaymentServiceProxy,
    ApiServiceProxies.LogServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule {}
